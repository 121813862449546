@import "assets/styles/table.css";
/* general styling */
body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.25;
    background: #fff;
}

header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    box-shadow: 0 1px 6px 0 rgba(142, 142, 142, 0.2), 0 1px 1px 0 rgba(243, 243, 243, 0.14), 0 2px 1px -1px rgba(204, 204, 204, 0.12);
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

header h4 {
    margin-left: 5px;
}

.not-found {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

